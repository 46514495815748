import React from 'react';
import ProductCard from '@app/components/cards/productCard';
import AnchorLink from '@app/components/ui/links/anchor-link';
import { productDto } from '@app/models/dtos/productDto';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import cn from 'classnames';

function Product({ product, className }: { product: productDto; className?: string }) {
    const { ref, isVisible } = useIntersectionObserver({ threshold: 0.3 });
    return (
        <div ref={ref} className={cn('flex w-full flex-col gap-5 md:flex-row', isVisible && 'in-view', className)}>
            <AnchorLink href={product.link} className="h-[339px] w-full overflow-hidden rounded-[8px] md:h-[520px] md:w-1/2 xl:h-[568px]" target="_blank">
                <div className="h-[339px] w-full rounded-[8px] duration-500 hover:scale-105 md:h-[520px] xl:h-[568px]">
                    <ImageRenderer className="h-full w-full" src={product.image} />
                </div>
            </AnchorLink>
            <ProductCard product={product} />
        </div>
    );
}

export default Product;
