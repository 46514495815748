import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';
import { productDto } from '@app/models/dtos/productDto';

function ProductCard({ product }: { product: productDto }) {
    return (
        <div
            style={{
                backgroundColor: product.backgroundColor
            }}
            className={`flex h-auto w-full flex-col rounded-[8px] p-6 md:h-[520px] md:w-1/2 md:p-10 xl:h-[568px]`}
        >
            <div className="flex flex-col gap-2">
                <p className="h2Responsive text-black-100">{product.title}</p>
                <AnchorLink className="p2 text-blue" target="_blank" href={product.link}>
                    {product.link}
                </AnchorLink>
            </div>
            <p className="pResponsive mb-16 mt-7 text-black-200 md:mb-20 md:mt-12">{product.description}</p>
            <div className="flex flex-col gap-2">
                <p className="h3 text-black-100">ROLE</p>
                <p className="p2 text-black-200">{product.role}</p>
            </div>
        </div>
    );
}

export default ProductCard;
