import React from 'react';
import { QuotesDto } from '@app/models/dtos/infoDto';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import cn from 'classnames';

function QuotesCard({ quotesDetails }: { quotesDetails: QuotesDto }) {
    const { isMobile } = useIsMobile();
    const { ref, isVisible } = useIntersectionObserver({ threshold: 0.3 });
    return (
        <div ref={ref} className={cn('col-auto flex h-[280px] flex-col gap-4 rounded-[8px] bg-white bg-backgroundsmall p-6 md:col-span-2 md:h-[430px] md:bg-background md:p-[56px]', isVisible && 'in-view', isMobile && 'animated-component-top-down')}>
            <p className="md:h1 h1Mobile tracking-[-0.4px] text-black-100">{quotesDetails.quotes}</p>
            <p className="md:p1 p1Mobile text-black-200">{quotesDetails.owner}</p>
        </div>
    );
}

export default QuotesCard;
