import { useState, useEffect, useRef } from 'react';

function useIntersectionObserver(options = {}) {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const hasAnimated = useRef(false); // Flag to track animation state

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries; // Assuming single element observation
            if (entry.isIntersecting && !hasAnimated.current) {
                setIsVisible(true);
                hasAnimated.current = true; // Set flag to prevent re-animation
            }
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect(); // Clean up on unmount
        };
    }, [options, hasAnimated]);

    return { ref, isVisible };
}

export default useIntersectionObserver;
