import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';
import { ProfileDetailsDto, SocialProviderDto } from '@app/models/dtos/infoDto';
import ImageRenderer from '@app/components/media-renderer/image-renderer';

function SocialProviderCard({ socialProviders, profileDetails }: { socialProviders: Array<SocialProviderDto>; profileDetails: ProfileDetailsDto }) {
    return (
        <div className="flex w-full flex-col justify-between gap-9 rounded-[8px] bg-purpleBg p-6 md:h-[410px] lg:max-w-[342px]">
            <div className="flex flex-col gap-2">
                {socialProviders.map((socialProvider) => {
                    return (
                        <AnchorLink href={socialProvider.link} target="_blank" key={socialProvider.name} className="flex gap-2 p-2">
                            <div className="h-6 w-6">
                                <ImageRenderer src={socialProvider.logo} />
                            </div>
                            <p className="p2 text-black-200">{socialProvider.name}</p>
                        </AnchorLink>
                    );
                })}
            </div>
            <AnchorLink href={profileDetails.cv} target="_blank" className="buttonText w-full rounded-[8px] bg-purple p-4 text-center font-urbanist normal-case tracking-[0.48px] text-white hover:bg-[#5D19AA]">
                Download My CV
            </AnchorLink>
        </div>
    );
}

export default SocialProviderCard;
