import React from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';
import Navbar from '@app/layouts/navbar';
import { ProfileDetailsDto } from '@app/models/dtos/infoDto';

interface ILayoutProps {
    className?: string;
    profileDetails: ProfileDetailsDto;
}

export default function Layout({ children, className, profileDetails }: React.PropsWithChildren<ILayoutProps>) {
    return (
        <div className="z-20 flex !min-h-screen !min-w-full flex-col items-center overflow-hidden bg-white">
            <div className="my-[15px] flex max-w-[1440px] flex-col items-center sm:my-5 lg:my-10">
                <Navbar profileDetails={profileDetails} />
                <Box className="float-right mt-[30px] w-full !bg-white lg:mt-[50px]" component="main" sx={{ display: 'flex', width: '100%' }}>
                    <div className={cn(`mx-[15px] h-full w-full sm:mx-5 lg:mx-10`, className)}>{children}</div>
                </Box>
            </div>
        </div>
    );
}
