import AboutMeCard from '@app/components/cards/aboutMeCard';
import ContactCard from '@app/components/cards/contactCard';
import HobbyCard from '@app/components/cards/hobbyCard';
import PersonalitiesCard from '@app/components/cards/personalitiesCard';
import ProfileCard from '@app/components/cards/profileCard';
import QuotesCard from '@app/components/cards/quotesCard';
import SocialProviderCard from '@app/components/cards/socialProviderCard';
import Testimonials from '@app/components/cards/testimonials';
import Product from '@app/components/product';
import Layout from '@app/layouts/_layout';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import { infoDto } from '@app/models/dtos/infoDto';
import { getRandomStringFromArray } from '@app/utils/stringUtils';
import cn from 'classnames';

export default function Home({ infoJson }: { infoJson: infoDto }) {
    const { ref, isVisible } = useIntersectionObserver({ threshold: 0.3 });

    const { isMobile } = useIsMobile();
    return (
        <Layout profileDetails={infoJson.profileDetails}>
            <main className={`flex flex-col items-center justify-center gap-[80px] md:gap-[120px]`}>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-3 lg:flex">
                    <ProfileCard profileDetails={infoJson.profileDetails} />
                    <QuotesCard quotesDetails={infoJson.quotesDetails} />
                    <PersonalitiesCard personalities={infoJson.personalities} />
                </div>
                <div className="flex flex-col gap-12">
                    {infoJson.products.map((product, index) => {
                        return <Product className={'animated-component-top-down'} key={product.title} product={product} />;
                    })}
                </div>

                <Testimonials testimonials={infoJson.testimonials} />
                <div ref={ref} className={cn('grid grid-cols-1 gap-5 md:grid-cols-3 lg:flex', isVisible && 'in-view', 'animated-component-top-down')}>
                    <AboutMeCard profileDetails={infoJson.profileDetails} />
                    <HobbyCard profileDetails={infoJson.profileDetails} />
                    <SocialProviderCard socialProviders={infoJson.socialProviders} profileDetails={infoJson.profileDetails} />
                </div>
                <ContactCard profileDetails={infoJson.profileDetails} facts={infoJson.facts} />
            </main>
        </Layout>
    );
}

export async function getServerSideProps() {
    const infoResponse = await fetch('https://kneerose.github.io/bucket/portfolio/info.json');

    if (infoResponse.status === 200) {
        const infoJson = await infoResponse.json();
        console.log(getRandomStringFromArray(infoJson.facts));
        return {
            props: {
                infoJson
            }
        };
    }
    return {
        notFound: true
    };
}
