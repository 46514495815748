import React from 'react';
import Ellipse from '@app/components/icons/ellipse';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import { ProfileDetailsDto } from '@app/models/dtos/infoDto';

function ProfileCard({ profileDetails }: { profileDetails: ProfileDetailsDto }) {
    return (
        <div className="relative flex h-[430px] w-full flex-col justify-end overflow-hidden rounded-[8px] bg-profile bg-cover bg-center bg-no-repeat lg:max-w-[370px]">
            <div className="h-[324px] w-[312px] duration-500 hover:scale-110">
                <ImageRenderer src={profileDetails.avatar} />
            </div>
            <div className="absolute bottom-[24px] right-[24px] flex items-center gap-1 rounded-[8px] bg-white px-2 py-1 tracking-[0.28px]">
                <Ellipse />
                <p className="label2">Open for relocation</p>
            </div>
        </div>
    );
}

export default ProfileCard;
